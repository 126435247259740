export const environment = {
    production: false,
    marketKey : 'globoo',
    admin: "https://api-admin.globboo.cl",
    checkout : "https://checkout.globboo.cl",
    cart: "https://api-cart-redis.globboo.cl",
    tarifa : "https://api-tarifas.globboo.cl",
    flow : "https://api-payment-w5537.ondigitalocean.app",
    marketplace : "https://globboo.cl",
    enterpriseId: "65a9be3af961c35186073a3b"
  };
