import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'resume-page',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'detail',
    loadChildren: () => import('./detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'currier',
    loadChildren: () => import('./currier/currier.module').then( m => m.CurrierPageModule)
  },
  {
    path: 'resume-page',
    loadChildren: () => import('./resume-page/resume-page.module').then( m => m.ResumePagePageModule)
  },
  {
    path: 'checkout-steps',
    loadChildren: () => import('./checkout-steps/checkout-steps.module').then( m => m.CheckoutStepsPageModule)
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./confirmation/confirmation.module').then( m => m.ConfirmationPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'thanks-page',
    loadChildren: () => import('./thanks-page/thanks-page.module').then( m => m.ThanksPagePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
