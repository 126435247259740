import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface Cart {
    cart ? : object,
    buyer ? :object,
    direction ? : object,
    discount ? :object
}
interface frontCart {
  seller: string,
  products : Array<object>,
  currier ? : object,
  sellerData ? : object,
  total : number
  discount ? : object
}
@Injectable({
  providedIn: 'root'
})
export class CartService {
  url = environment.cart;


  total ;
  parsedCart;
  totalWithoutDiscount;
  discountCode;


  constructor(private http:HttpClient) { }

  getCart(cartUuid : String){
    cartUuid = cartUuid.trim();
    return this.http.get<Cart>(`${this.url}/cart?uuid=${cartUuid.trim()}` , {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
      });
  }
  setCart(uuid : String,payload){
    uuid = uuid.trim();
    return this.http.post<any[]>(`${this.url}/cart?uuid=${uuid.trim()}` ,payload, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
      });
  }

  addCoupon(uuid: string, couponCode){
    const payload = {
      code: couponCode
    }
    return this.http.post<any[]>(`${this.url}/addCoupon?uuid=${uuid.trim()}` , payload, {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
      });
  }

  deleteCoupon(uuid: string){
    return this.http.post<any[]>(`${this.url}/deleteCoupon?uuid=${uuid.trim()}` , {
        headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
      });
  }


  parseToApi(cart){
    const payload = {
      cart:{}
    }
    for(const id in cart){
      const seller = cart[id].seller;
      let products = {}
      for(let product of cart[id].products){
        products[product.code] = product;
      }
      payload.cart[seller] = {products};
    }
    return payload;
  }
  parseCurrentCart(cart : Object,parsedCart : frontCart[]){    
    let finalTotal : number = 0;
    const sellers = Object.keys(cart)
    for(const seller of sellers){
      const currier = cart[seller].currier;
      const sellerData = cart[seller].seller;
      const products = Object.values(cart[seller].products);
      //const total = products.reduce( (acc,value)=> acc + value['total'],0) as number;
      let totalOriginal = 0;
      let total = 0
      let totalWithDiscount = 0;
      for(const product of products){
        const originalPrice = product['originalPrice'];
        const quantity = product['quantity'];
        totalOriginal += originalPrice * quantity;
        total += product['total'];
        totalWithDiscount = quantity * product['value'];
        //const originalPrice = products.reduce( (acc,value) => acc+ (value['originalPrice']),0);
      }
      console.log('originalPrice',totalOriginal)
      console.log('totalWithDiscount',totalWithDiscount)
      const temp = {seller,products,total,currier,sellerData} as frontCart;
      parsedCart.push(temp)
      finalTotal += total;
    }
    return finalTotal;
  }
  valueInRange(product){
    console.log('value',product.value)
    console.log('original',product.originalPrice)
    const quantity = product.quantity
    if(!product.originalPrice){
      product.originalPrice = product.value;
    }
    const originalPrice = product.originalPrice;    
    product.value = originalPrice;
    product.discount = 0;
    if(product && product.ranges){      
      const result = product.ranges.filter(range => this.filterByRange(range,quantity))
      if(result.length > 0){
        product.value = this.calculateResult(product,result[0]);
        product.discount = 100 - Math.round( (product.value * 100) /originalPrice )
        return product.value
      }
      return product.value;      
    }
  }
  calculateResult(stock,range){
    const price = stock.value;
    const map = {
      'discount':this.calculatePercentage,
      'value' : this.setValue
    }    
    const fun = map[range.type];    
    return  fun.call(this,{price,value:range.value});
  }
  setValue(data){
    const {value} = data;    
    return value;
  }
  filterByRange(range,quantity){    
    if(range.from <= quantity && range.to >= quantity){
      return true
    }
    return false
  }
  calculatePercentage(data) {  
    const {price ,value} = data;    
    if(value){      
      return price - Math.round(( value / 100) * price);
    }    
    return price;
  }



  getCurrentCart(cartId : String){
    return new Promise( (resolve,reject) =>{
      this.getCart(cartId).subscribe(currentCart => {            
        if(currentCart){
          this.total = this.parseCurrentCart(currentCart.cart,this.parsedCart = []);
          this.discountCode = currentCart.discount;   
        }
        console.log('this.currentCart',this.parsedCart);
        console.log('this.total',this.total);
  
        for(const cart of this.parsedCart){
          for(const p of cart.products){
            this.valueInRange(p)
          }
        }
        this.calculateTotal();
        resolve(
          {total: this.total,
            totalWithoutDiscount: this.totalWithoutDiscount,
            discount : this.calculateDiscount(),
            parsedCart : this.parsedCart,
            discountCode : this.discountCode
          }
        )
      })
    })
  }


  calculateTotal(){    
    let total = 0;
    let totalWithoutDiscount = 0;
    this.totalWithoutDiscount = 0;
    for(const seller of this.parsedCart){
      total += seller.products.reduce( (acc,value)=> acc + (value['value'] * value['quantity']),0) as number;      
      totalWithoutDiscount += seller.products.reduce( (acc,value)=> acc + (value['originalPrice'] * value['quantity']),0) as number;
    } 
    this.total = (total == NaN ? 0 : total);
    this.totalWithoutDiscount = 0
    if(totalWithoutDiscount > 0 ){
      this.totalWithoutDiscount = totalWithoutDiscount;
    }
    console.log('calculate total',this.totalWithoutDiscount)
  }

  calculateDiscount(){
    const result = this.totalWithoutDiscount - this.total
    if(result  < 0){
      return 0;
    }
    return result;
  }

  addQuantity(item){
    if(item.quantity < item.stock){
      item.quantity = item.quantity + 1;
    }    
    this.valueInRange(item);
    this.calculateTotal();
  }
  removeQuantity(item){
    item.quantity = item.quantity - 1;
    this.valueInRange(item);
    this.calculateTotal();    
  }

 

}

