import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SellerService {  
  private url: string = environment.admin;  
  constructor(private http:HttpClient) {    
  }
  listAll() {    
    return this.http.get<any[]>(`${this.url}/sellers/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  
    });
  }

  async list() {    
    return this.http.get<any[]>(`${this.url}/seller/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')

    });
  }

  getOne(id){
    return this.http.get<any[]>(`${this.url}/seller/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')     
    });
  }
  update(id,seller){
    delete seller.__v;
    return this.http.patch<any[]>(`${this.url}/seller/${id}` , seller,{
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
   
    });
  }

  insert(seller,targetEmail){
    seller.status = true;

    const form={
      seller,targetEmail
    }
    console.log(form)
    return this.http.post<any[]>(`${this.url}/seller/` ,form, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
 
    });
  }


}
