import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StoreService { 
  
  private url = environment.admin;
  constructor(private http:HttpClient) {    
  }
  
  async list() {    

    return this.http.get<any[]>(`${this.url}/store/` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
     
    });
  }
  getOne(id){
    return this.http.get<any[]>(`${this.url}/store/${id}` , {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
        
    });
  }
  getbyEnterprise(enterpriseId,pagetype){
    const store={
      enterpriseId,
      pagetype
    }

    return this.http.post<any[]>(`${this.url}/store/getByEnterprise/` ,store, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
     
    });
  }

  getCommonConfigByEnterprise(enterpriseId,componentName){
    const store={
      enterpriseId,
      componentName
    }

    return this.http.post<any[]>(`${this.url}/store/getCommonConfigByEnterprise/` ,store, {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
     
    });
  }

}
