import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { cities } from '../../assets/json/cities';
import { environment } from 'src/environments/environment';

export interface region {
  'name':string,
  'romanNumber':string,
  'abbreviation':string,
  'communes' : Array<any>
}

export interface country {
  'name': string,
  'regions':Array<region>
}
export interface currierResponse {
  days: number;
  value: number;
  currier: string;
}

export interface direction {
  street : string,
  phone : string,
  number : number,
  optional? : string,
  commune : string,
  region : string,  
}

export interface currierPayload {
  destiny : string,
  origin : string ,
  kilo : number,
  curriers : Array<string>,
  packages : Array<Object>
}

@Injectable({
  providedIn: 'root'
})
export class CurrierService {
  url = environment.tarifa;
  constructor(private http: HttpClient) { }

  getAvailableCurriers() {
    return this.http.get<any>(`${this.url}/generic/available`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }
  getCurrierTarifa(payload : currierPayload) {
    return this.http.post<currierResponse[]>(`${this.url}/generic/tarifa/multiple`, payload, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }
  getAllCities(): country{
    return  cities;
  }
}

