import { Component, OnInit } from '@angular/core';
import { StoreService } from './_services/store.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  faviconUrl:string;
  tabTitle:string='Checkout';
  checkoutLogo:string;
  loadingLogo=false;

  constructor(private storeService: StoreService) {}

  ngOnInit(){
    this.loadingLogo = true;
      //set favicon
      this.storeService.getCommonConfigByEnterprise(environment.enterpriseId,'tab').subscribe(data=>{
        this.faviconUrl = data['props'].imgUrl;
        this.tabTitle = data['name'];
        document.getElementById('appFavicon').setAttribute('href', this.faviconUrl);
        document.getElementById("tabTitle").innerHTML = 'Checkout ' + this.tabTitle;
        this.storeService.getCommonConfigByEnterprise(environment.enterpriseId,'logo').subscribe(logo=>{
          this.checkoutLogo=logo['props'].imgUrl;
          this.loadingLogo=false;
        },err=>{
          this.loadingLogo=false;
        })
      },err=>{
        this.loadingLogo=false;
        document.getElementById("tabTitle").innerHTML = this.tabTitle;
      })
     

  }

  navigateToOrigin(){
    window.location.href = environment.marketplace;
  }
}
